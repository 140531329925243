@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(../assets/fonts/Poppins-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Arial-Bold';
  font-weight: 'bold';
  src: local('Arial-Bold'), url(../assets/fonts/Arial-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Arial-Regular';
  src: local('Arial-Regular'), url(../assets/fonts/Arial-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: local('Montserrat-Regular'), url(../assets/fonts/Montserrat-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat-Bold';
  font-weight: 'bold';
  src: local('Montserrat-Bold'), url(../assets/fonts/Montserrat-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-Regular';
  src: local('Poppins-Regular'), url(../assets/fonts/Poppins-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-Bold';
  font-weight: 'bold';
  src: local('Poppins-Bold'), url(../assets/fonts/Poppins-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-Light';
  font-weight: 'light';
  src: local('Poppins-Light'), url(../assets/fonts/Poppins-Light.ttf) format('truetype');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

table {
  width: 100%;
  table-layout: fixed;
  margin-top: 25px;
  margin-bottom: 25px;
}

table tr th {
  text-align: left;
  background: gray;
  color: white;
  font-weight: bold;
}

tbody tr:nth-child(odd) {
  background-color: white;
}

tbody tr:nth-child(even) {
  background-color: #ddd;
}

th,
td {
  padding: 0.25rem;
  border: 1px solid #999;
}

/* HTML editor overrides */
/* Insert image dialog */
.se-dialog-image .se-dialog-body > .se-dialog-form:nth-child(2),
.se-dialog-image .se-dialog-body > .se-dialog-form:nth-child(3),
.se-dialog-image .se-dialog-form-footer,
.se-dialog-image .se-dialog-footer > div:first-child {
  display: none;
}

/* Insert link dialog */
.se-dialog-inner .se-dialog-content:nth-child(3) .se-dialog-form-footer {
  display: none;
}
