.login-container {
  width: 480px;
}

img {
  width: 50%;
}

@media (max-width: 500px) {
  .login-container,
  img {
    width: 80%;
  }

  .login-container {
    text-align: center;
  }

  .text-field-inner {
    flex-direction: column;
  }

  input {
    width: 100% !important;
  }

  label {
    width: 100% !important;
    margin-bottom: 10px;
  }
}
