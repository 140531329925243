.sidebar-nav:hover .sidebar-animation {
  opacity: 1;
}

.update-app-data {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  padding: 10px;
  background: rgba(255, 255, 255, 0.3);
}

@media (max-width: 1023px) {
  .update-app-data {
    position: static;
    margin: 1rem -1rem -1rem;
    width: calc(100% + 2rem);
  }
}
